import React from "react";
import { useTranslation } from "react-i18next";
import { Card } from "clutch/src/Card/Card.jsx";

import { getCurrentSet } from "@/game-tft/constants.mjs";
import {
  goldIncome,
  Interest,
  WinLossStreak,
  XPTable,
} from "@/game-tft/constants-xp.mjs";
import { ContentContainer, TableRow } from "@/game-tft/GuideExp.style.jsx";
import { URLS } from "@/game-tft/guides-constants.mjs";
import Coin from "@/inline-assets/hextech-match-gold.svg";
import StreakWin from "@/inline-assets/streak-win.svg";
import TftLevelingIcon from "@/inline-assets/tft-leveling.svg";
import Battle from "@/inline-assets/tft-round-elimination.svg";
import PageContainer from "@/shared/PageContainer.jsx";
import PageHeader from "@/shared/PageHeader.jsx";

const goldIncomeIcons = {
  "Base Income": <Coin />,
  Interest: <Coin />,
  "Streak Bonus": <StreakWin />,
  "PvP Win": <Battle />,
};

const ExpGuide = () => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <PageHeader
        title={t(
          "tft:guidesGoldCalculationsAndExpTable",
          "Gold Calculations / Exp Table - Guides",
        )}
        links={URLS}
      />
      <ContentContainer>
        <div className="section">
          <Card padding="0">
            <header>{t("tft:goldIncome", `Gold Income`)} </header>
            <TableRow>
              <div className="col">
                <span className="type-caption">{t("common:type", "Type")}</span>
              </div>
              <div className="col">
                <span className="type-caption">
                  {t("tft:earnedGold", "Earned Gold")}
                </span>
              </div>
            </TableRow>
            {goldIncome.map((row, i) => {
              return (
                <TableRow key={i}>
                  <div className="col type-body2 title">
                    {goldIncomeIcons[row.name]}
                    {row.name}
                  </div>
                  <div className="type-body2 col value">
                    <Coin />
                    {row.value}
                  </div>
                </TableRow>
              );
            })}
          </Card>
          <Card padding="0">
            <header>{t("tft:interest", `Interest`)}</header>
            <TableRow>
              <div className="col">
                <span className="type-caption">{t("common:gold", "Gold")}</span>
              </div>
              <div className="col">
                <span className="type-caption">
                  {t("tft:earnedGold", "Earned Gold")}
                </span>
              </div>
            </TableRow>
            {Interest.map((row, i) => {
              return (
                <TableRow key={i}>
                  <div className="col type-body2 title">
                    <Coin />
                    {row.name}
                  </div>
                  <div className="type-body2 col value">
                    <Coin />
                    {row.value}
                  </div>
                </TableRow>
              );
            })}
          </Card>
          <Card padding="0">
            <header>{t("tft:winOrLossStreak", `Win/Loss Streak`)}</header>
            <TableRow>
              <div className="col">
                <span className="type-caption">
                  {t("common:count", "Count")}
                </span>
              </div>
              <div className="col">
                <span className="type-caption">
                  {t("tft:earnedGold", "Earned Gold")}
                </span>
              </div>
            </TableRow>
            {WinLossStreak.map((row, i) => {
              return (
                <TableRow key={i}>
                  <div className="col type-body2 title">
                    <StreakWin style={{ fill: row.color }} />
                    {t("tft:winOrLoss", "{{count}} Win/Loss", {
                      count: row.count,
                    })}
                  </div>
                  <div className="type-body2 col value">
                    <Coin />
                    {row.value}
                  </div>
                </TableRow>
              );
            })}
          </Card>
        </div>
        <Card padding="0">
          <header>{t("tft:expTable", `Exp Table`)}</header>
          <TableRow>
            <div className="col">
              <span className="type-caption">{t("common:level", "Level")}</span>
            </div>
            <div className="col">
              <span className="type-caption">{t("tft:needXP", "Need XP")}</span>
            </div>
          </TableRow>
          {XPTable.map((row, i) => {
            return (
              <TableRow key={i}>
                <div className="col type-body2 title">
                  <TftLevelingIcon />
                  {t("tft:lvlValue", "Lvl {{level}}", {
                    level: row.startLevel,
                  })}
                  {"  "}→{"  "}
                  {t("tft:lvlValue", "Lvl {{level}}", {
                    level: row.endLevel,
                  })}
                </div>
                <div className="type-body2 col value">
                  {t("tft:xp", "{{value}} XP", { value: row.needXP })}
                </div>
              </TableRow>
            );
          })}
        </Card>
      </ContentContainer>
    </PageContainer>
  );
};

export default ExpGuide;

export function meta(_params, searchParams) {
  let set = searchParams?.get("set") || getCurrentSet().replace("set", "");
  set = set.replace("_", ".");
  return {
    title: [
      "tft:meta.exp.title",
      "TFT Gold Income / EXP - TFT Guides - Set {{set}}",
      { set },
    ],
    description: [
      "tft:meta.exp.description",
      "Unlock Winning Strategies with Gold Income and EXP calculations in TFT! Optimize Comps, Builds, and Meta Using Key Stats.",
      { set },
    ],
  };
}
