export const goldIncome = [
  {
    name: "Base Income",
    value: "5",
    i18n: "tft:baseIncome",
  },
  {
    name: "Interest",
    value: "0-5",
    i18n: "tft:interest",
  },
  {
    name: "Streak Bonus",
    value: "1-3",
    i18n: "tft:streakBonus",
  },
  {
    name: "PvP Win",
    value: "1",
    i18n: "tft:pvpWin",
  },
];

export const Interest = [
  {
    name: "0-9",
    value: "0",
  },
  {
    name: "10-19",
    value: "1",
  },
  {
    name: "20-29",
    value: "2",
  },
  {
    name: "30-39",
    value: "3",
  },
  {
    name: "40-49",
    value: "4",
  },
  {
    name: "50+",
    value: "5",
  },
];

export const WinLossStreak = [
  {
    count: "1",
    color: "#C1D4D8",
    value: "0",
  },
  {
    count: "2",
    color: "#9DD5D7",
    value: "1",
  },
  {
    count: "3",
    color: "#79D6D6",
    value: "1",
  },
  {
    count: "4",
    color: "#30D9D4",
    value: "2",
  },
  {
    count: "5+",
    color: "#30D9D4",
    value: "3",
  },
];

export const XPTable = [
  { startLevel: 1, endLevel: 2, needXP: 0 },
  { startLevel: 2, endLevel: 3, needXP: 2 },
  { startLevel: 3, endLevel: 4, needXP: 6 },
  { startLevel: 4, endLevel: 5, needXP: 10 },
  { startLevel: 5, endLevel: 6, needXP: 24 },
  { startLevel: 6, endLevel: 7, needXP: 40 },
  { startLevel: 7, endLevel: 8, needXP: 60 },
  { startLevel: 8, endLevel: 9, needXP: 84 },
  { startLevel: 9, endLevel: 10, needXP: 100 },
];
